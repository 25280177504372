<template>
  <v-form
    ref="form"
    @submit.prevent="onSubmit"
  >
    <v-text-field
      v-model="formData.subject"
      :label="labels.subject"
      :error="Boolean(errors.subject)"
      :messages="errors.subject"
      :rules="requiredRule"
      data-cy="ticket-subject"
    />
    <v-textarea
      v-model="formData.currentBehavior"
      class="mb-2"
      auto-grow
      rows="1"
      :label="labels.currentBehavior"
      :rules="requiredRule"
      data-cy="ticket-current-behavior"
    />
    <v-textarea
      v-model="formData.desiredBehavior"
      class="mb-2"
      auto-grow
      rows="1"
      :label="labels.desiredBehavior"
      :rules="requiredRule"
      data-cy="ticket-desired-behavior"
    />
    <v-textarea
      v-model="formData.relatedUrls"
      :label="labels.relatedUrls"
      :error="Boolean(errors.relatedUrls)"
      :messages="errors.relatedUrls"
      data-cy="ticket-related-urls"
    />

    <div>
      <v-file-input
        v-model="formData.attachments"
        :label="$t('support.fields.addFiles')"
        multiple
        :error="Boolean(errors.attachments)"
        :messages="errors.attachments"
        data-cy="ticket-attachement"
      />
      <template v-if="formData.attachments.length > 1">
        <div
          v-for="attachment in formData.attachments"
          :key="attachment.name"
          class="d-flex align-center"
        >
          <v-icon
            class="delete-icon"
            @click="removeAttachment(attachment)"
          >
            {{ deleteIcon }}
          </v-icon>
          {{ attachment.name }}
        </div>
      </template>
    </div>
    <core-element-errors-message
      :show-errors="showApiErrors"
      :errors="errors"
    />
    <div class="mt-5 text-center">
      <v-btn
        rounded
        :loading="submittingTicket"
        depressed
        data-cy="ticket-modal-submit"
        color="primary"
        type="submit"
      >
        {{ $t('support.fields.submitSupportTicket') }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { DELETE_ICON } from '@/constants/app'

export default {
  data: () => ({
    formData: {
      attachments: []
    },
    deleteIcon: DELETE_ICON
  }),
  computed: {
    ...mapState('support', ['submittingTicket', 'newTicketSubmitted', 'errors', 'showApiErrors']),
    requiredRule () {
      return [v => Boolean(v) || this.$t('errors.fieldIsRequired')]
    },
    labels () {
      return {
        subject: this.$t('support.fields.subject'),
        relatedUrls: this.$t('support.fields.relatedUrls'),
        currentBehavior: this.$t('support.fields.currentBehavior'),
        desiredBehavior: this.$t('support.fields.desiredBehavior'),
        attachments: this.$t('support.fields.attachment')
      }
    }
  },
  created () {
    this.resetFormData()
  },
  methods: {
    ...mapActions('support', ['createTicket']),
    resetFormData () {
      this.formData = {
        attachments: []
      }
    },
    removeAttachment (attachment) {
      this.formData.attachments = this.formData.attachments.filter(at => at !== attachment)
    },
    onSubmit () {
      const validFormData = this.$refs.form.validate()
      if (validFormData) {
        const requestPayload = {
          ...this.formData,
          clientId: this.$route.params.clientId
        }

        this.createTicket(requestPayload)
      }
    }
  }
}
</script>

<style scoped>
.delete-icon:hover {
  color: var(--v-primary-base);
}
</style>
